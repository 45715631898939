<template>
  <div class="map_box">
     <div id="map_echarts_box" v-loading="loading">
      <div class="flex" style="margin:10px">
        <dateComp style="margin-right:10px" :hval="date" @change="dateChange" />
        <btnGroup @search="search" @reset="reset" />
      </div>
      <div id="map_echarts"></div>
    </div>
  </div>

</template>

<script>
import '../../../../node_modules/echarts/map/js/china'
import { mapData } from '@/api'
import { removeRepeat, formatDate } from '@/utils/common'
import moment from 'moment'
export default {
  data () {
    return {
      loading: false,
      startTime: '',
      endTime: '',
      date: []
    }
  },
  mounted () {
    this.endTime = formatDate(new Date())
    this.startTime = moment().subtract(6, 'days').format('YYYY-MM-DD')// 6天前
    this.date = [new Date(this.startTime), new Date(this.endTime)]
    this.allRequest()
    window.addEventListener('resize', this.resizeCharts, true)
    this.$erd('map_echarts_box', 'map_echarts')
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeCharts, true)
  },
  methods: {
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    search () {
      this.allRequest()
    },
    reset () {
      this.endTime = formatDate(new Date())
      this.startTime = moment().subtract(6, 'days').format('YYYY-MM-DD')// 6天前
      this.date = [new Date(this.startTime), new Date(this.endTime)]
      this.allRequest()
    },
    // 拼接数据 [{name:'',value:[精度，维度、数量]}]
    convertData (data) {
      var res = []
      for (var i = 0; i < data.length; i++) {
        var geoCoord = this.geoCoordMap[data[i].name]
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value)
          })
        }
      }
      return res
    },
    // 初始化处理数据
    initHandle () {
      this.year = ['服务站分布', '工单分布', '上门单分布']
      this.categoryData = [] // 类型数据
      this.barData = [] // 柱状图数据
      this.colorIndex = 0
      this.colors = [
        ['#1DE9B6', '#FFC809', '#04B9FF', '#5DBD32', '#FFC809', '#FB95D5', '#BDA29A', '#6E7074', '#546570', '#C4CCD3'],
        ['#37A2DA', '#67E0E3', '#32C5E9', '#9FE6B8', '#FFDB5C', '#FF9F7F', '#FB7293', '#E062AE', '#E690D1', '#E7BCF3', '#9D96F5', '#8378EA', '#8378EA'],
        ['#DD6B66', '#759AA0', '#E69D87', '#8DC1A9', '#EA7E53', '#EEDD78', '#73A373', '#73B9BC', '#7289AB', '#91CA8C', '#F49F42']
      ]
      for (var i = 0; i < this.mapData.length; i++) {
        this.mapData[i].sort(function sortNumber (a, b) {
          return a.value - b.value
        })
        this.barData.push([])
        this.categoryData.push([])
        for (var j = 0; j < this.mapData[i].length; j++) {
          this.barData[i].push(this.mapData[i][j].value)
          this.categoryData[i].push(this.mapData[i][j].name)
        }
      }
    },
    // 同时发送三个请求 分别获取三种数据
    allRequest () {
      this.mapData = [[], [], []]
      const p0 = mapData({ type: 1, startTime: this.startTime, endTime: this.endTime })
      const p1 = mapData({ type: 2, startTime: this.startTime, endTime: this.endTime })
      const p2 = mapData({ type: 3, startTime: this.startTime, endTime: this.endTime })
      this.loading = true
      Promise.all([p0, p1, p2]).then(res => {
        const totalArr = [] // 各个总数
        this.loading = false
        let geoCoordMapArr = []
        const geoCoordMapObj = {}
        res.forEach((item, index) => {
          if (item.code === '1') {
            geoCoordMapArr.push(...item.data.list)
            if (item.data.list.length > 0) {
              totalArr.push(item.data.totalCount)
            } else {
              totalArr.push(0)
            }
            this.mapData[index] = item.data.list.map(item => {
              return {
                year:
                  index === 0
                    ? '服务站分布'
                    : index === 1
                      ? '工单分布'
                      : '上门单分布',
                name: item.areaName.replace(/市/, ''),
                value: item.count
              }
            })
            // 将三种数据的城市合并起来然后去重
            geoCoordMapArr = removeRepeat(geoCoordMapArr, 'areaName')
            // 拼接这种数据  geoCoordMap = {杭州: [119.5313, 29.8773],武汉: [114.31, 30.52],太原: [112.549, 37.857]}
            geoCoordMapArr.forEach(item => {
              geoCoordMapObj[item.areaName.replace(/市/, '')] = [
                +item.longitude,
                +item.latitude
              ]
            })
            this.geoCoordMap = geoCoordMapObj
            this.getJson()
          }
        })
        this.totalArr = totalArr
      })
    },
    getJson () {
      // 获取本地地图json数据注册地图
      this.$axios.get('/mapData.json').then(res => {
        this.initHandle()
        this.init(res.data)
      })
    },
    resizeCharts () {
      const myChart = this.$echarts.init(document.getElementById('map_echarts'))
      myChart.resize()
    },
    init (json) {
      const myChart = this.$echarts.init(document.getElementById('map_echarts'))
      this.$echarts.registerMap('china', json)
      const optionXyMap01 = {
        timeline: {
          data: this.year,
          axisType: 'category',
          autoPlay: true,
          playInterval: 5000,
          left: '10%',
          right: '10%',
          bottom: '1%',
          width: '80%',
          label: {
            normal: {
              textStyle: {
                color: '#ddd'
              }
            },
            emphasis: {
              textStyle: {
                color: '#fff'
              }
            }
          },
          symbolSize: 10,
          lineStyle: {
            color: '#555'
          },
          checkpointStyle: {
            borderColor: '#777',
            borderWidth: 2
          },
          controlStyle: {
            showNextBtn: true,
            showPrevBtn: true,
            normal: {
              color: '#666',
              borderColor: '#666'
            },
            emphasis: {
              color: '#aaa',
              borderColor: '#aaa'
            }
          }
        },
        baseOption: {
          animation: true,
          animationDuration: 1000,
          animationEasing: 'cubicInOut',
          animationDurationUpdate: 1000,
          animationEasingUpdate: 'cubicInOut',
          grid: {
            right: '1%',
            top: '70',
            bottom: '8%',
            width: '20%'
          },
          tooltip: {
            trigger: 'axis', // hover触发器
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
              shadowStyle: {
                color: 'rgba(150,150,150,0.1)' // hover颜色
              }
            }
          },
          geo: {
            show: true,
            map: 'china',
            roam: true,
            zoom: 1,
            center: [113.83531246, 34.0267395887],
            label: {
              emphasis: {
                show: false
              }
            },
            itemStyle: {
              normal: {
                borderColor: 'rgba(147, 235, 248, 1)',
                borderWidth: 1,
                areaColor: {
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: 'rgba(128, 217, 248, 1)',
                // shadowColor: 'rgba(255, 255, 255, 1)',
                shadowOffsetX: -2,
                shadowOffsetY: 2,
                shadowBlur: 10
              },
              emphasis: {
                areaColor: '#389BB7',
                borderWidth: 0
              }
            }
          }
        },
        options: []
      }
      for (var n = 0; n < this.year.length; n++) {
        optionXyMap01.options.push({
          backgroundColor: '#013954',
          title: [
            {
              text: '租葛企服数据分布',
              left: '35%',
              top: '3%',
              textStyle: {
                color: '#fff',
                fontSize: 25
              }
            },
            {
              id: 'statistic',
              text: `${this.year[n]}：${this.totalArr[n]}`,
              left: '75%',
              top: '3%',
              textStyle: {
                color: '#fff',
                fontSize: 25
              }
            }
          ],
          xAxis: {
            type: 'value',
            scale: true,
            position: 'top',
            min: 0,
            boundaryGap: false,
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              margin: 2,
              textStyle: {
                color: '#aaa'
              }
            }
          },
          yAxis: {
            type: 'category',
            //  name: 'TOP 20',
            nameGap: 16,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#ddd'
              }
            },
            axisTick: {
              show: false,
              lineStyle: {
                color: '#ddd'
              }
            },
            axisLabel: {
              interval: 0,
              textStyle: {
                color: '#ddd'
              }
            },
            data: this.categoryData[n]
          },

          series: [
            // 地图
            {
              type: 'map',
              map: 'china',
              geoIndex: 0,
              aspectScale: 0.75, // 长宽比
              showLegendSymbol: false, // 存在legend时显示
              label: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false,
                  textStyle: {
                    color: '#fff'
                  }
                }
              },
              roam: true,
              itemStyle: {
                normal: {
                  areaColor: '#031525',
                  borderColor: '#FFFFFF'
                },
                emphasis: {
                  areaColor: '#2B91B7'
                }
              },
              animation: false,
              data: mapData
            },
            // 地图中闪烁的点
            {
              //  name: 'Top 5',
              type: 'effectScatter',
              coordinateSystem: 'geo',
              data: this.convertData(
                this.mapData[n]
                  .sort(function (a, b) {
                    return b.value - a.value
                  })
              ),
              // symbolSize: function (val) {
              //   return val[2] / 10
              // },
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke'
              },
              hoverAnimation: true,
              label: {
                normal: {
                  formatter: '{b}',
                  position: 'right',
                  show: true
                }
              },
              itemStyle: {
                normal: {
                  color: this.colors[this.colorIndex][n],
                  shadowBlur: 10,
                  shadowColor: this.colors[this.colorIndex][n]
                }
              },
              zlevel: 1
            },
            // 柱状图
            {
              zlevel: 1.5,
              type: 'bar',
              symbol: 'none',
              barMaxWidth: '20',
              itemStyle: {
                normal: {
                  color: this.colors[this.colorIndex][n]
                }
              },
              data: this.barData[n]
            }
          ]
        })
      }
      myChart.setOption(optionXyMap01)
    }
  }
}
</script>

<style>
.map_box {
  height: calc(100% - 110px);
}
#map_echarts_box {
  width: 100%;
  height: 100%;
}
#map_echarts {
  width: 100%;
  height: 100%;
}
</style>
